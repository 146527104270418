
import React, { useEffect, useState } from "react";
import { _getStorageValue, getUserId } from "../../comman/localStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatePicker from "react-datepicker";
import {
    faMinusCircle,
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";
import { USER_ID } from "../../comman/constants";
import { useDispatch } from "react-redux";
import { saveAdvariskData } from "../../action/actionEmployeeAnalytics";

const leftFields = [
    {
        label: "Reference Number *",
        key: "reference_number",
        value: "",
        isRequired:true
    },
    {
        label: "Project Case Name *",
        key: "project_case_name",
        value: "",
        isRequired:true
    },
    {
        label: "State *",
        key: "state",
        value: "",
        isRequired:true
    },
    {
        label: "District *",
        key: "district",
        value: "",
        isRequired:true
    },
    {
        label: "Village / City / Town / Locality *",
        key: "village_city_town_locality",
        value: "",
        isRequired:true

    },
    {
        label: "Pincode *",
        key: "pin_code",
        value: "",
        isRequired:true

    },
    {
        label: "House / Flat / Plot Number / Wing Floor Number",
        key: "house_flat_plot_number_wing_floor_number",
        value: ""
    },
    {
        label: "Building Number / Tower Name / Road",
        key: "building_number_tower_name_road",
        value: ""
    },
]

const rightFields = [
    {
        label: "Survey Khasra Number / Phase Number / CTS Number",
        key: "survey_khasra_number_phase_number_cts_number",
        value: ""
    },
    {
        label: "Propety Address Line 1 *",
        key: "property_address_line_1",
        value: "",
        isRequired:true
    },
    {
        label: "Property Address Line 2",
        key: "property_address_line_2",
        value: ""
    },
    {
        label: "Registration Number",
        key: "registration_number",
        value: ""
    },
    {
        label: "Sub Registrar Office",
        key: "sub_registrar_office",
        value: ""
    },
]

const AdvaRiskComp = (props) => {

    const [lFields, setLFields] = useState(leftFields);
    const [isCheck, setCheck] = useState(false);

    const [rFields, setRFields] = useState(rightFields);
    const [owner_names, setOwnerNames] = useState([""]);
    const [date, setDate] = useState("");
    const [isShowResult,setShowResult] =useState(false);
    const [isDisabled,setDisabled] = useState(false);
    const [loading,setLoading] = useState(false);

    const dispatch =  useDispatch();


    const onInputchangeLeft = (e, key) => {
        let inputValue = e.target.value;
        let outputData = lFields.map((md, index) => {
            if (key === index) {
                return { ...md, value: inputValue }
            } else {
                return md
            }
        });
        setLFields(outputData);
    };

    const onInputchangeRight = (e, key) => {
        let inputValue = e.target.value;
        let outputData = rFields.map((md, index) => {
            if (key === index) {
                return { ...md, value: inputValue }
            } else {
                return md
            }
        });
        setRFields(outputData);
    };

    const onOwnerNameUpdate = (e, key) => {
        let inputValue = e.target.value;
        let outputData = owner_names.map((md, index) => {
            if (key === index) {
                return inputValue
            } else {
                return md
            }
        });

        setOwnerNames(outputData);
    }

    const submit=()=>{
        setCheck(true);
        let requiredFieds  =["property_address_line_1","pin_code","village_city_town_locality","village_city_town_locality","district","state","project_case_name","reference_number"]
        let formData = new FormData

        let payload = {}

     rFields.forEach((data)=>{
        payload={...payload,[data.key]:data.value }
       })
       lFields.forEach((data)=>{
        payload={...payload,[data.key]:data.value }
       })
       payload = {...payload,"service_type": "property_check",owner_names: owner_names.filter(fd=>fd!=""),registration_date:date }

       let isExecute = true;

       requiredFieds.forEach(fd=>{
        if(payload[fd]==""){
            isExecute = false 
        }
       })

       console.log("isExecute",isExecute);
       if(owner_names[0].length==0 ){
        isExecute = false 
       }

       if(isExecute){
        setDisabled(true)
        setLoading(true);
        const successCallBack=(response)=>{
            setLoading(false);
            toast.success("Success", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    
        const failurCallback=()=>{
            setLoading(false);
            toast.error("Please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    
        _getStorageValue(USER_ID).then((id) => {
            dispatch(saveAdvariskData(id,payload,successCallBack, failurCallback));
        });

    }
 
    }

    return (<div style={{ paddingTop: 100 }} className="report-container ">
        <div className="site-vist sitrepot branch-container  ">
            <h3> Advarisk Check </h3>
        </div>
      {<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            < div style={{ width: "45%" }}>
                {lFields.map((md, index) => {
                    return (<><div style={{ width: "100%" }} className="customer-details-sec">
                        <label className="customer-title">{md.label}</label>
                        <input
                            type="text"
                            value={md.value}
                            onChange={(e) => {
                                onInputchangeLeft(e, index);
                            }}
                            className="customer-desc"
                        />
                    </div>
                    {isCheck && md.isRequired && md.value=="" && <div style={{color:"red",fontSize:12,textAlign:"end"}}>required *</div>}
                    </>)
                })}
                   <div className="customer-details-sec width45">
                <div className="add-Button"> <button        
                           disabled={isDisabled}
              onClick={() => { submit() }}>{loading?<div className="loader-circle-v1"/> :"Submit"}</button> </div>
              </div>
            </div>
            < div style={{ width: "45%" }}>
                {rFields.map((md, index) => {
                    return (<><div style={{ width: "100%" }} className="customer-details-sec">
                        <label className="customer-title">{md.label}</label>
                        <input
                            type="text"
                            value={md.value}
                            onChange={(e) => {
                                onInputchangeRight(e, index);
                            }}
                            className="customer-desc"
                        />
                    </div>
                    {isCheck && md.isRequired && md.value=="" && <div style={{color:"red",fontSize:12,textAlign:"end"}}>required *</div>}
                    </>)
                })}


                <div style={{ width: "100%" }} className="customer-details-sec">
                    <label className="customer-title">{"Registration Date"}</label>
                    <div className="down-arrow" style={{ width: "45%" }}>
                        <ReactDatePicker className="customer-desc-date" value={date}
                            onChange={(date) => {
                                console.log("data", date);
                                let newDateFormat = moment(new Date(date)).format("YYYY-MM-DD");
                                setDate(newDateFormat)
                            }} />
                    </div>
                </div>
                <div style={{ width: "100%", justifyContent: "space-between", alignItems: "flex-start" }} className="customer-details-sec">

                    <label style={{
                        alignItems: "start",
                        width: '50%'
                    }} className="customer-title">{"Owner Names*"}</label>
                    <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>

                        {owner_names.map((md, index) => <div style={{
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            padding: 2,
                        }}>
                            <input style={{
                                width: "85%",
                            }}
                                type="text"
                                value={md}
                                onChange={(e) => {
                                    onOwnerNameUpdate(e, index) 
                                }}
                                className="customer-desc"
                            />
                            {index === owner_names.length - 1 ? <FontAwesomeIcon onClick={() => {
                                setOwnerNames([...owner_names, ""])
                            }} style={{ width: '10%', marginLeft: "5%" }} icon={faPlusCircle} rotate={45} color="green" size="lg" /> : <FontAwesomeIcon onClick={() => {
                                let removedOwnar = owner_names.filter((_, findex) => findex != index)
                                setOwnerNames(removedOwnar);
                            }} style={{ width: '10%', marginLeft: "5%" }} icon={faMinusCircle} rotate={45} color="red" size="lg" />}
                        </div>)}
                        {isCheck && owner_names[0].length==0 && <div style={{color:"red",fontSize:12,textAlign:"end"}}>required *</div>}
                    </div>
                </div>
            </div>
        </div> 
       
        }
    </div>)

}

export default AdvaRiskComp

