import React, { useEffect, useState } from "react";
import Header from "../header";

import { bindActionCreators } from "redux";
import { stateUser } from "../../action/getState";
import { cityUser } from "../../action/getCity";
import { _getStorageValue, getUserId } from "../../comman/localStorage";
import { connect } from "react-redux";
import { USER_ID } from "../../comman/constants";


const inputData = {
    "Leasable Area Retail":"",
    "Leasable Area Office":"",
    "Leasable Area Others":"",
    "Leased Area Retail":"",
    "Leased Area Office":"",
    "Leased Area Others":"",
    "Weighted Average Rental Retail":"",
    "Weighted Average Rental Office":"",
    "Weighted Average Rental Others":"",
    "No of Parkings":"",
    "Rent/Parking":"",
    "Monthly CAM Charges":"",
    "City Classification":0,
    "Age of the Property":0,
    "Grade of the Property":0,
    "Green Building Rating":0,
    "Maintainenace of the Property":0,
    "State of the Property":0,
    "Loction Demographics":0
}
const gradeOfTheProperty=[
  {label:"Select",value:0},
    {label:"Grade A",value:10,cal:3},
    {label:"Grade B",value:5,cal:2},
    {label:"Grade C",value:2,cal:1},
    {label:"Others",value:0,cal:0},
];

const locationDemographics = [
  {label:"Select",value:0},
    {label:"City Center",value:25,cal:5},
    {label:"Pheriperal",value:15,cal:10},
    {label:"Outer Business District",value:10,cal:15},
];

const buildingRatingData =  [
  {label:"Select",value:0},
    {label:"Platinum",value:10,cal:5},
    {label:"Gold",value:5,cal:10},
    {label:"Silver",value:2,cal:15},
    {label:"Others",value:0,cal:0},
];

const propertAge =  [

  {label:"Select",value:0},

    {label:"<5 Years",value:15,cal:1},
    {label:"5 to 10 Years",value:10,cal:2},
    {label:">10 Years",value:5,cal:3},
];

const propertyState =  [
    {label:"Select",value:0},
    {label:"Bare Shell",value:2,cal:1},
    {label:"Warm Shell",value:5,cal:2},
    {label:"Fitout ",value:10,cal:3},
];


const securityDeposit = {
    "No of Months":6
}

const rateOfIntrest = {
    "Interest":1
}

const cityData=[
    {label:"Select",value:0},
    {label:"Delhi NCR",value:20},
    {label:"Mumbai",value:20},
    {label:"Banglore",value:20},
    {label:"Hyderabad",value:20},
    {label:"Chennai",value:20},
    {label:"Kolkata",value:20},
    {label:"Ahmedabad",value:20},
    {label:"Pune",value:20},
    {label:"Surat",value:20},   
    {label:"Indore",value:20},
    {label:"Chandigarh",value:20},
    {label:"Tier 2",value:10},
    {label:"Tier 3",value:5},
];

const maintainenaceData =[ 
    {label:"Select",value:0},
    {label:"Good",value:10},
    {label:"Average",value:5},
    {label:"Poor",value:2},
];


 const RentalCalc = (props) => {
    const [input, setInput]=useState(inputData);
    const [selectedGrade, setSelectedGrade]=useState(gradeOfTheProperty[0]);
    const [demographics, setDemographics]=useState(locationDemographics[0]);
    const [buildingRating, setbuildingRate]=useState(buildingRatingData[0]);
    const [ageProperty, setAgeProperty]=useState(propertAge[0]);
    const [stateProperty, setStateProperty]=useState(propertyState[0]);
    const [city, setCity]=useState(cityData[0]);
    const [ maintainenace, setMaintainenace]=useState(maintainenaceData[0]);

    const logicSheet={
        "Retail Unleased Area": Number(input["Leasable Area Retail"]) - Number(input["Leased Area Retail"]),
        "Office Unleased Area": Number(input["Leasable Area Office"]) - Number(input["Leased Area Office"]),
        "Others Unleased Area": Number(input["Leasable Area Others"]) - Number(input["Leased Area Others"]),
        "Rental - Retail Unleased Area":((Number(input["Weighted Average Rental Retail"]) * (5/100)) + Number(input["Weighted Average Rental Retail"])),
        "Rental - Office Unleased Area":((Number(input["Weighted Average Rental Office"]) * (5/100)) + Number(input["Weighted Average Rental Office"])),
        "Rental - Others Unleased Area":((Number(input["Weighted Average Rental Others"]) * (5/100)) + Number(input["Weighted Average Rental Others"])),
        "Gross Income":function(){
            return(( Number(input["Leased Area Retail"]) * Number(input["Weighted Average Rental Retail"]) ) + (Number(input["Leased Area Office"]) * Number(input["Weighted Average Rental Office"]))
             + (Number(input["Leased Area Others"]) * Number(input["Weighted Average Rental Others"])) + (this["Retail Unleased Area"]*  this["Rental - Retail Unleased Area"])
             + (this["Office Unleased Area"]*  this["Rental - Office Unleased Area"]) +  (this["Others Unleased Area"]*  this["Rental - Others Unleased Area"])
            )
        },
        "Gross Rental from Parking":Number(input["No of Parkings"]) * Number(input["Rent/Parking"]),
        "Gross CAM Recievables": (Number(input["Monthly CAM Charges"]) * (input["Leased Area Retail"] + Number(input["Leased Area Office"]) + Number(input["Leased Area Others"]))),
        "CAM Markup": selectedGrade.cal,
        "Net Margin Revenue from CAM Charges (Monthly)":function () {
            return( this["Gross CAM Recievables"]* (this["CAM Markup"]/100))
        },
        "No of Months of Security Deposit" :securityDeposit["No of Months"],
        "Total Security Deposit":function(){
            return( this["Gross Income"]() * (this["No of Months of Security Deposit"] /100) )
        },
        "Rate of Interest":rateOfIntrest["Interest"],
        "Income from Security Deposit":function(){
            return ( this["Total Security Deposit"]() * (this["Rate of Interest"]/100))
        },
        "Gross Potential Annual Revenue":function(){
            return (((this["Gross Income"]() + this["Gross Rental from Parking"] + this["Net Margin Revenue from CAM Charges (Monthly)"]())*12) + this["Income from Security Deposit"]())
        },
        "Overall Vaccancy Risk taken in account": demographics.cal,
        "Effective Annual Revenue" : function () {
            return ((this["Gross Potential Annual Revenue"]() )* (1-(Number(this["Overall Vaccancy Risk taken in account"])/100)))
        },
        "Operation and Maintenance Charge (%EAR)": buildingRating.cal,
        "Operation and Maintenance Charge":function(){
            return (this["Effective Annual Revenue"]() * (this["Operation and Maintenance Charge (%EAR)"]/100))
        },
        "Property Tax, Insurance (%EAR)":ageProperty.cal,
        "Property Tax, Insurance":function(){
            return (this["Effective Annual Revenue"]() * (this["Property Tax, Insurance (%EAR)"]/100));
        },
        "Repair and Retroit (%EAR)":stateProperty.cal,
        "Repair and Retroit":function(){
            return (this["Effective Annual Revenue"]() * (this["Repair and Retroit (%EAR)"]/100));
        },
        "Total Operating Expenditure" : function(){
            return (this["Operation and Maintenance Charge"]() + this["Property Tax, Insurance"]() + this["Repair and Retroit"]());
        },
        "Net Cashflow from Operations": function(){
            return (this["Effective Annual Revenue"]() - this["Total Operating Expenditure"]() );
        },
        "Net Annual Revenue": function(){
            return (this["Net Cashflow from Operations"]());
        },
        "City Classification":city.value,
        "Age of the Property":ageProperty.value,
        "Grade of the Property":selectedGrade.value,
        "Green Building Rating":buildingRating.value ,
        "Maintainenace of the Property":maintainenace.value,
        "State of the Property":stateProperty.value,
        "Loction Demographics": demographics.value,
        "Total %":function(){
            return (this["City Classification"] + this["Age of the Property"] + this["Grade of the Property"]
            + this["Green Building Rating"] + this["Maintainenace of the Property"] + this["State of the Property"]
            + this["Loction Demographics"]
            )
        },
        "Capitalized At":function(){
            if(this["Total %"]() >= 95){
                return 6.5;
            }else if(this["Total %"]() >= 90){
                return 6.75;
            }else if(this["Total %"]() >= 85){
                return 7;
            }else if(this["Total %"]() >= 80){
                return 7.5;
            }else if(this["Total %"]() >= 75){
                return 8;
            }else if(this["Total %"]() >= 70){
                return 8.5;
            }else if(this["Total %"]() >= 65){
                return 9;
            }else if(this["Total %"]() >= 60){
                return 9.5;
            }else if(this["Total %"]() <= 50){
                return 10;
            }   
        },
        "Capitalized Rate":function(){
            return (this["Net Annual Revenue"]() / (this["Capitalized At"]()/100))
        }
    }

    const output={
        "Appraised Rate/Sft": (logicSheet["Capitalized Rate"]() / ( Number((input["Leasable Area Others"]) + Number(input["Leasable Area Office"]) + Number(input["Leasable Area Retail"] )))),
        "Property Value in INR": logicSheet["Capitalized Rate"]()
    }

    const onInputchange=(e, label,defaultNumber=true)=>{
      if((/^[+-]?(\d+(\.\d*)?|\.\d+)$/.test(e.target.value)&&defaultNumber)|| e.target.value ===""){
        let value = e.target.value;
        let updatedInput = {...input, [label]:value}
        console.log("value",updatedInput);
        setInput(updatedInput);
      }else if(!defaultNumber){
        let value = e.target.value;
        let updatedInput = {...input, [label]:value}
        setInput(updatedInput);
      }
    }

    return (<div>
        <div className="geo-tracking-container">
            {/* <Header /> */}
            <div style={{marginRight:50,marginLeft:50}}className="">
            </div>
            <>
            </>

            <div style={{marginRight:50,marginLeft:50}}> 
            <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between" }}>
            
            < div style={{width:"45%"}}>
            <div style={{}}className="">
                <div style={{ height: 30 }} className="site-vist sitrepot branch-container">
                    <h3> Total Leasable Area  </h3>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Retail (Sft)"}</label>
            <input
                  type="text"
                  value={input["Leasable Area Retail"]}
                  onChange={(e)=>{
                    onInputchange(e,"Leasable Area Retail")
                  }}
                  className="customer-desc"
                  />

            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Others (Sft)"}</label>
            <input
                  type="text"
                  value={input["Leasable Area Office"]}
                  onChange={(e)=>{
                    onInputchange(e,"Leasable Area Office")
                  }}
                  className="customer-desc"
              
                  />
            </div>

            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Office (Sft)"}</label>
            <input
                  type="text"
                  value={input["Leasable Area Others"]}
                  onChange={(e)=>{
                    onInputchange(e,"Leasable Area Others")
                  }}
                  className="customer-desc"
              
                  />
            </div>
            <div style={{}}className="">
                <div style={{ height: 30 }} className="site-vist sitrepot branch-container">
                    <h3> Total Leased Area</h3>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Retail (Sft)"}</label>
            <input
                  type="text"
                  value={input["Leased Area Retail"]}
                  onChange={(e)=>{
                    onInputchange(e,"Leased Area Retail")
                  }}
                  className="customer-desc"
              
                  />
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Office (Sft)"}</label>
            <input
                  type="text"
                  value={input["Leased Area Office  (Sft)"]}
                  onChange={(e)=>{
                    onInputchange(e,"Leased Area Office")
                  }}
                  className="customer-desc"
              
                  />
            </div>

            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Others (Sft)"}</label>
            <input
                  type="text"
                  value={input["Leased Area Others"]}
                  onChange={(e)=>{
                    onInputchange(e,"Leased Area Others")
                  }}
                  className="customer-desc"
              
                  />
            </div>

            <div style={{}}className="">
                <div style={{ height: 30 }} className="site-vist sitrepot branch-container">
                    <h3> Weighted Average Rental  </h3>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Retail (INR/Sft/Month)"}</label>
            <input
                  type="text"
                  value={input["Weighted Average Rental Retail"]}
                  onChange={(e)=>{
                    onInputchange(e,"Weighted Average Rental Retail")
                  }}
                  className="customer-desc"
              
                  />
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Office (INR/Sft/Month)"}</label>
            <input
                  type="text"
                  value={input["Weighted Average Rental Office"]}
                  onChange={(e)=>{
                    onInputchange(e,"Weighted Average Rental Office")
                  }}
                  className="customer-desc"
              
                  />
            </div>

            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Others (INR/Sft/Month)"}</label>
            <input
                  type="text"
                  value={input["Weihted Average Rental Ogthers"]}
                  onChange={(e)=>{
                    onInputchange(e,"Weighted Average Rental Others")
                  }}
                  className="customer-desc"
                  />
            </div>

            <div style={{}}className="">
                <div style={{ height: 30 }} className="site-vist sitrepot branch-container">
                    <h3> Parking Area Details </h3>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"No of Parkings (Count)"}</label>
            <input
                  type="text"
                  value={input["No of Parkings"]}
                  onChange={(e)=>{
                    onInputchange(e,"No of Parkings")

                  }}
                  className="customer-desc"
                  />
            </div>
            <div style={{width:"100%"}}  className="customer-details-sec">
            <label className="customer-title">{"Rent/Parking (INR/Lot/Month)"}</label>
            <input
                  type="text"
                  value={input["Rent/Parking"]}
                  onChange={(e)=>{
                    onInputchange(e,"Rent/Parking")
                  }}
                  className="customer-desc"
                  />
            </div>
          
            </div>

            < div style={{width:"45%"}}>
         


            <div style={{}}className="">
                <div style={{ height: 30 }} className="site-vist sitrepot branch-container">
                    <h3> CAM Charges </h3>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Monthly CAM Charges (INR/Sft/Month)"}</label>
            <input
                  type="text"
                  value={input["Monthly CAM Charges"]}
                  onChange={(e)=>{
                    onInputchange(e,"Monthly CAM Charges")
                  }}
                  className="customer-desc"
                //   style={{
                //     backgroundColor:isDisabled?"lightgrey":"transparent",
                //     opacity:isDisabled?"0.5":"1",
                // }}
                  //disabled={isDisabled}
                  />
            </div>
       

            <div style={{}}className="">
                <div style={{ height: 30 }} className="site-vist sitrepot branch-container">
                    <h3> Property Features	 </h3>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">

            <label className="customer-title">{"City Classification"}</label>
            <div className="down-arrow " style={{width:"45%"}}>
                  <select
                    value={input["City Classification"]}
                    onChange={(e) => {
                      onInputchange(e,"City Classification",false)
                      let filterData = cityData.filter(fd=>fd.label == e.target.value)[0]
                      setCity(filterData)
                    }}
                    className="customer-desc"
                    style={{width:"100%"}}
                  >
                    {cityData.map((data, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={data.label}
                                    label={data.label}
                                  />
                                );
                              })}
                  </select>
                </div>
            </div>
             <div style={{width:"100%"}} className="customer-details-sec">

            <label className="customer-title">{"Age of the Property"}</label>
            <div className="down-arrow " style={{width:"45%"}}>
                  <select
                    value={input["Age of the Property"]}
                    onChange={(e) => {
                      onInputchange(e,"Age of the Property",false)
                      let filterData = propertAge.filter(fd=>fd.label == e.target.value)[0]
                      setAgeProperty(filterData)
                    }}
                    className="customer-desc"
                    style={{width:"100%"}}
                  >
                    {propertAge.map((data, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={data.label}
                                    label={data.label}
                                  />
                                );
                              })}
                  </select>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">

<label className="customer-title">{"Grade of the Property"}</label>
<div className="down-arrow " style={{width:"45%"}}>
      <select
        value={input["Grade of the Property"]}
        onChange={(e) => {
          onInputchange(e,"Grade of the Property",false)
          let filterData = gradeOfTheProperty.filter(fd=>fd.label == e.target.value)[0]
          setSelectedGrade(filterData)
        }}
        className="customer-desc"
        style={{width:"100%"}}
      >
        {gradeOfTheProperty.map((data, key) => {
                    return (
                      <option
                        key={key}
                        value={data.label}
                        label={data.label}
                      />
                    );
                  })}
      </select>
    </div>
</div>
<div style={{width:"100%"}} className="customer-details-sec">

<label className="customer-title">{"Green Building Rating"}</label>
<div className="down-arrow " style={{width:"45%"}}>
      <select
        value={input["Green Building Rating"]}
        onChange={(e) => {
          onInputchange(e,"Green Building Rating",false)
          let filterData = buildingRatingData.filter(fd=>fd.label == e.target.value)[0]
          setbuildingRate(filterData)
        }}
        className="customer-desc"
        style={{width:"100%"}}
      >
        {buildingRatingData.map((data, key) => {
                    return (
                      <option
                        key={key}
                        value={data.label}
                        label={data.label}
                      />
                    );
                  })}
      </select>
    </div>
</div>
<div style={{width:"100%"}} className="customer-details-sec">

<label className="customer-title">{"Maintainenace of the Property"}</label>
<div className="down-arrow " style={{width:"45%"}}>
      <select
        value={input["Maintainenace of the Property"]}
        onChange={(e) => {
          onInputchange(e,"Maintainenace of the Property",false)
          let filterData = maintainenaceData.filter(fd=>fd.label == e.target.value)[0]
          setMaintainenace(filterData)
        }}
        className="customer-desc"
        style={{width:"100%"}}
      >
        {maintainenaceData.map((data, key) => {
                    return (
                      <option
                        key={key}
                        value={data.label}
                        label={data.label}
                      />
                    );
                  })}
      </select>
    </div>
</div>

<div style={{width:"100%"}} className="customer-details-sec">

<label className="customer-title">{"State of the Property"}</label>
<div className="down-arrow " style={{width:"45%"}}>
      <select
        value={input["State of the Property"]}
        onChange={(e) => {
          onInputchange(e,"State of the Property",false)
          let filterData = propertyState.filter(fd=>fd.label == e.target.value)[0]
          setStateProperty(filterData)
        }}
        className="customer-desc"
        style={{width:"100%"}}
      >
        {propertyState.map((data, key) => {
                    return (
                      <option
                        key={key}
                        value={data.label}
                        label={data.label}
                      />
                    );
                  })}
      </select>
    </div>
</div>

<div style={{width:"100%"}} className="customer-details-sec">

<label className="customer-title">{"Loction Demographics"}</label>
<div className="down-arrow " style={{width:"45%"}}>
      <select
        value={input["Loction Demographics"]}
        onChange={(e) => {
          onInputchange(e,"Loction Demographics",false)
          let filterData = locationDemographics.filter(fd=>fd.label == e.target.value)[0]
          setDemographics(filterData)
        }}
        className="customer-desc"
        style={{width:"100%"}}
      >
        {locationDemographics.map((data, key) => {
                    return (
                      <option
                        key={key}
                        value={data.label}
                        label={data.label}
                      />
                    );
                  })}
      </select>
    </div>
</div>

            <div style={{}}className="">
                <div style={{ height: 30 }} className="site-vist sitrepot branch-container">
                    <h3> Output </h3>
                </div>
            </div>
            <div style={{width:"100%"}} className="customer-details-sec">
            
            <label className="customer-title">{"Appraised Rate/Sft"}</label>
            <input
                  type="text"
                  value={isNaN(output["Appraised Rate/Sft"].toFixed(2))?"0.00":output["Appraised Rate/Sft"].toFixed(2)}
                  className="customer-desc"
                  style={{
                    backgroundColor:"lightgrey",
                  }}
                  disabled={true}
                  />
            </div>
            <div style={{width:"100%"}}  className="customer-details-sec">
            <label className="customer-title">{"Property Value in INR"}</label>
            <input
                type="text"
                value={isNaN(output["Property Value in INR"].toFixed(2))?"0.00":output["Property Value in INR"].toFixed(2)}
                className="customer-desc"
                style={{
                  backgroundColor:"lightgrey",
                }}
                disabled={true}
                  />
            </div>
         
            </div>
            </div>
            </div>
        </div>
    </div>)
}

const mapStateToProps = (state) => ({
    
  });
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
      
     
      },
      dispatch
    );
  }

  export default connect(mapStateToProps, mapDispatchToProps)(RentalCalc);
  